import { Environment } from './environment.model';

const url = 'https://ad6-test.flusso.nl/api/v1';

export const environment: Environment = {
  production: false,
  name: 'TEST',
  url,
  description: 'Alpe d\'Huez',
  ad6Map: {
    northEast: { latitude: 45.093308, longitude: 6.032288 },
    southWest: { latitude: 45.057328, longitude: 6.078553 },
  },
  sentry: {
    tracing: {
      tracingOrigins: ['localhost', url],
    },
  },
};
